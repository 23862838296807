@import styles/sass/abstracts

.root
  top: 50%
  left: 50%
  transform: translateX(-50%) translateY(-50%)
  position: fixed
  color: $text-black
  display: flex
  flex-direction: column
  width: 97.2rem
  height: 39.1rem
  margin: 0 auto
  +res-md-min
    flex-direction: row
    .main
      align-self: center
    .imageBox
      height: auto
      width: 39rem
      min-height: 32rem

  +res-sm-max
    width: 33.5rem
    height: 49.3rem

.close
  position: absolute
  top: 2rem
  right: 2rem
  svg
    +styleIcon(2.4rem, $fill-black)
  +res-sm-max
    background-color: var(--fill-neutral-30)
    border-radius: 50%

.main
  padding: 6rem 7.2rem
  +vSpace(2rem)
  +res-md-min
    flex: 1
  +res-sm-max
    padding: 2rem 1.5rem
    text-align: center
    display: flex
    flex-direction: column
    justify-content: space-between

.content
  +vSpace(1.2rem)

.title
  +typoH4
  +res-sm-max
    font-size: 2rem
    line-height: 2.6rem

.description
  +typoTextMd
  +customContent
  +res-sm-max
    font-size: 1.4rem
    line-height: 2.4rem
    font-weight: 400

.imageBox
  height: 30rem
  +res-sm-max
    height: 20rem
    img
      max-width: 100%

  img
    width: 100%

.ctaBox
  display: flex
  gap: 1.2rem

  button, a
    width: 50%

  +res-sm-max
    align-items: center
    flex-direction: column
    button, a
      width: 75%
